import React from "react";
import PropTypes from "prop-types";
import { PopupButton } from "@typeform/embed-react";

const Button = ({ children, inverted, bigger, typeformId }) => {
  if (typeformId)
    return (
      <PopupButton
        id={typeformId}
        className={`
            btn p-2 md:px-4 md:px-6 border-3 rounded-md font-black md:font-bold text-base 
            duration-300 
            ${
              inverted
                ? "bg-primary border-black text-white shadow-invButton hover:shadow-invButtonHover focus:shadow-invButtonActive"
                : "bg-white border-black text-black shadow-button  hover:shadow-buttonHover focus:shadow-buttonActive"
            }  
            ${bigger ? "" : ""}
            
            `}
      >
        {children}
      </PopupButton>
    );

  return (
    <button
      className={`
        btn p-2 md:px-4 md:px-6 border-3 rounded-md font-black md:font-bold text-base 
        duration-300 
        ${
          inverted
            ? "bg-primary border-black text-white shadow-invButton hover:shadow-invButtonHover focus:shadow-invButtonActive"
            : "bg-white border-black text-black shadow-button  hover:shadow-buttonHover focus:shadow-buttonActive"
        }  
        ${bigger ? "" : ""}
        
        `}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  inverted: PropTypes.bool,
  bigger: PropTypes.bool,
};

Button.defaultProps = {
  children: "Scopri ora",
  inverted: false,
  bigger: false,
};
export default Button;
