import React from "react";
import PropTypes from "prop-types";

const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];

const Subtitle = ({
  white,
  center,
  bold,
  children,
  big,
  opaque,
  bolder,
  headingLevel,
}) => {
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : "";
  const TitleTag = validHeadingLevels.includes(safeHeading) ? safeHeading : "p";
  return (
    <TitleTag
      className={`
      leading-tight tracking-tight
      ${center ? "text-center" : ""} 
      ${white ? "text-white" : ""}
      ${bold ? "font-semibold" : ""}
      ${bolder ? "font-sans font-bold" : "font-sans_text"}
      ${big ? "text-xl md:text-2xl" : "text-md md:text-lg lg:text-xl"}
      ${opaque ? "opacity-70" : ""}`}
    >
      {children}
    </TitleTag>
  );
};

Subtitle.defaultProps = {
  white: false,
  opaque: false,
  big: false,
  bold: false,
  bolder: false,
  center: false,
  children: "",
};

Subtitle.propTypes = {
  white: PropTypes.bool,
  opaque: PropTypes.bool,
  big: PropTypes.bool,
  bold: PropTypes.bool,
  bolder: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

export default Subtitle;
