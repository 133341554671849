import React from "react";
import { Text, Title } from "components";

import img_num0 from "../../images/numeri/0.svg";
import img_num1 from "../../images/numeri/1.svg";
import img_num2 from "../../images/numeri/2.svg";
import img_num3 from "../../images/numeri/3.svg";
import img_num4 from "../../images/numeri/4.svg";
import img_num5 from "../../images/numeri/5.svg";
import img_num6 from "../../images/numeri/6.svg";
import img_num7 from "../../images/numeri/7.svg";
import img_num8 from "../../images/numeri/8.svg";
import img_num9 from "../../images/numeri/9.svg";
import img_num10 from "../../images/numeri/10.svg";

const number_images = [
  img_num0,
  img_num1,
  img_num2,
  img_num3,
  img_num4,
  img_num5,
  img_num6,
  img_num7,
  img_num8,
  img_num9,
  img_num10,
];

const BlocksListNumberedItem = ({ title, description, image, uniqueID }) => {
  return (
    <div className="blocks-list-item">
      <div
        className={`flex flex-col items-center justify-center px-8 md:px-28 py-16 md:flex-row`}
      >
        <div className="px-8">
          <img
            src={number_images[uniqueID]}
            alt={uniqueID}
            className="md:h-52 w-auto object-contain"
          />
        </div>
        <div className="md:w-2/3 ">
          <Title size={"small"} headingLevel="h4">
            {title}
          </Title>
          <Text size="small">{description}</Text>
        </div>
      </div>
    </div>
  );
};

const BlocksListImageItem = ({ title, description, image, uniqueID }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center px-8 md:px-28 py-16 ${
        uniqueID % 2 === 0 ? "md:flex-row bg-red-100" : "md:flex-row"
      }`}
    >
      <div className="px-8 mr-24">
        <img 
          src={image} 
          alt={title} 
          className="md:h-56 w-auto object-contain" />
      </div>
      <div className="md:w-2/5 ">
        <div className="mb-4">
          <Text size={"base"} weight={"black"}>
            {title}
          </Text>
        </div>
        <Text size="base">{description}</Text>
      </div>
    </div>
  );
};

// dynamically choose list type with validation
const validTypes = ["numbered", "images"];
const Types = {
  numbered: BlocksListNumberedItem,
  images: BlocksListImageItem,
};

const BlocksList = ({ blocks, type = "numbered" }) => {
  const safeType = type ? type.toLowerCase() : "";
  const BlockItem = validTypes.includes(safeType)
    ? Types[safeType]
    : Types["numbered"];

  return (
    <div className="blocks-list">
      {blocks.map(({ title, description, image }, key) => {
        return (
          <BlockItem
            title={title}
            description={description}
            image={image}
            key={key}
            uniqueID={key + 1}
          />
        );
      })}
    </div>
  );
};

export default BlocksList;
