import React from "react";

const Box = ({children}) => {
    return (
        <div className="p-4 rounded-md my-4 mr-auto border-3 border-black border-solid shadow-button">
            <div className="leading-tight text-black text-justify">
                {children}
            </div>
        </div>
    );
};
export default Box;
