import { graphql, useStaticQuery, Link } from "gatsby";
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { Text, Button } from "components";
import { gsap } from "gsap";
import { ROUTES, SERVICES, WORK_WITH_US } from "helpers/route";
// import useWindowDimensions from "helpers/useWindowDimensions";
import Facebook from "images/svg/facebook.svg";
import Linkedin from "images/svg/linkedin.svg";
import Instagram from "images/svg/instagram.svg";
import Twitter from "images/svg/twitter.svg";
import Logo from "images/svg/logo-2-linee.svg";
import Lottie from "react-lottie";
import * as hamburgerAnimation from "images/json/hamburger.json";
import * as dotAnimation from "images/json/notification.json";
import * as dotAnimationWhite from "images/json/notification-white.json";
import storageAvailable from "../../helpers/storageAvailable";

// eslint-disable-next-line
const Header = forwardRef(
  ({ showBlogNotification, setShowBlogNotification, lastPost, color }, ref) => {
    const [isExpanded, toggleExpansion] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [changeColor, setChangeColor] = useState(false);
    const [menuTl] = useState(gsap.timeline({ paused: true }));
    const [masterTl] = useState(gsap.timeline({ paused: true, delay: 1.7 }));
    const toCalculateWidthRef = useRef();
    const toExpandRef = useRef();
    const firstReveal = useRef();
    const secondReveal = useRef();
    const hamburgerRef = useRef();
    const [isPaused, setIsPaused] = useState(true);
    const [isStopped, setIsStopped] = useState(true);
    const [speed, setSpeed] = useState(1);

    const handlePlay = useCallback(() => {
      setIsStopped(false);
      setIsPaused(false);
    }, []);

    const handleStop = useCallback(() => {
      setIsStopped(true);
    }, []);

    const handlePause = useCallback(() => {
      setIsPaused(!isPaused);
    }, [isPaused]);

    const hamburgerOptions = {
      loop: false,
      autoplay: false,
      animationData: hamburgerAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const notificationOptions = {
      loop: true,
      autoplay: true,
      animationData: dotAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const notificationWhiteOptions = {
      loop: true,
      autoplay: true,
      animationData: dotAnimationWhite,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const handleScroll = () => {
      if (typeof window !== "undefined") {
        const { pageYOffset, innerHeight } = window;

        setChangeColor(false);
        if (pageYOffset > innerHeight) {
          setChangeColor(true);
        }

        setScrollPosition(pageYOffset);
      }
    };

    useEffect(() => {
      if (typeof window !== "undefined") {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }, []);

    const toggleMenuTl = () => {
      const lottieRef = hamburgerRef.current;

      menuTl.reversed(!menuTl.reversed());
      if (menuTl.reversed()) {
        masterTl.reverse();
        setSpeed(-1);
        handlePlay();
        setTimeout(() => {
          toggleExpansion(!isExpanded);
        }, 1600);
      } else {
        setSpeed(1);
        handlePlay();
        toggleExpansion(!isExpanded);
        masterTl.play();
      }

      // masterTl.play();
    };

    const { site } = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `);

    /* Hamburger menu entries reveal animation */
    useEffect(() => {
      const firstElement = firstReveal.current;
      const secondElement = secondReveal.current;

      const firstItems = firstElement.querySelectorAll("li");
      firstItems.forEach((element, index) => {
        const firstTl = gsap
          .timeline({ delay: 0.1 })
          .from(element, { yPercent: -30, autoAlpha: 0, rotation: -5 })
          .to(element, { yPercent: 0, autoAlpha: 1, rotation: 0 });
        masterTl.add(firstTl, index * 0.2);
      });

      const secondItems = secondElement.querySelectorAll("li");
      secondItems.forEach((element, index) => {
        const firstTl = gsap
          .timeline()
          .from(element, { yPercent: -30, autoAlpha: 0, rotation: -5 })
          .to(element, { yPercent: 0, autoAlpha: 1, rotation: 0 });
        masterTl.add(firstTl, "<");
      });
    }, []);

    useEffect(() => {
      const element = toExpandRef.current;
      const parentWidthElement = toCalculateWidthRef.current;

      menuTl
        .fromTo(
          element,
          {
            delay: 1,
            opacity: 1,
            width: parentWidthElement.offsetWidth,
            height: parentWidthElement.offsetHeight + 4,
            xPercent: -6,
            y: 9,
            zIndex: -1,
          },
          {
            opacity: 1,
            xPercent: -6,
            y: 9,
            scale: 50,
            zIndex: -1,
            duration: 1,
            ease: "Expo.easeIn",
          }
        )
        .reverse();
    }, []);

    return (
      <>
        <header
          className={`fixed top-0 flex my-8 px-8 md:px-12 w-screen z-50`}
          id="header"
          ref={ref}
        >
          <div
            className={`${
              isExpanded ? "block md:hidden" : "hidden"
            } bg-transparent absolute w-screen rounded-lg top-20`}
          >
            <div className="" id="toReveal">
              <ul ref={firstReveal}>
                {ROUTES.map((link) => (
                  <li
                    className="text-white text-4xl font-semibold"
                    key={link.title}
                  >
                    <Link
                      className="block font-semibold no-underline md:inline-block md:mt-0 md:ml-8"
                      key={link.title}
                      to={link.route}
                      onClick={() => {
                        if (storageAvailable) {
                          localStorage.setItem("last-post", lastPost.id);
                          setShowBlogNotification(false);
                        }
                      }}
                    >
                      <div className="flex flex-row items-center">
                        {link.title}
                        {link.route === "/blog" && showBlogNotification && (
                          <div className="pb-3">
                            <Lottie
                              options={notificationWhiteOptions}
                              width={30}
                              height={30}
                              speed={0.5}
                              isStopped={false}
                              isPaused={false}
                            />
                          </div>
                        )}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="mt-8" ref={secondReveal}>
                {SERVICES.map((link) => (
                  <li className="text-white " key={link.title}>
                    <Link
                      className="block no-underline md:inline-block md:mt-0 md:ml-8 opacity-90"
                      key={link.title}
                      to={link.route}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex justify-start mt-8">
                <div className="mr-4 flex items-center justify-center">
                  <Facebook className="object-cover w-8 h-auto fill-current text-white" />
                </div>
                <div className="mr-4 flex items-center justify-center">
                  <Twitter className="object-cover w-8 h-auto fill-current text-white" />
                </div>
                <div className="mr-4 flex items-center justify-center">
                  <Instagram className="object-cover w-8 h-auto fill-current text-white" />
                </div>
                <div className="mr-4 flex items-center justify-center">
                  <Linkedin className="object-cover w-8 h-auto fill-current text-white" />
                </div>
              </div>
              <ul className="mt-8">
                <li className="text-white text-xs">Nexha S.r.l.</li>
                <li className="text-white text-xs">
                  Via Frà Bartolomeo 82/A, Prato
                </li>
                <li className="text-white text-xs">+39 0574 020861</li>
                <li className="text-white text-xs">P.Iva: 02450300971</li>
                <li className="text-white text-xs">
                  Trame Digitali è partner TDVB Group
                </li>
              </ul>
            </div>
          </div>
          <div
            ref={toCalculateWidthRef}
            className={`${
              isExpanded
                ? "bg-transparent"
                : changeColor
                ? "bg-white border-black border-4 shadow-button pl-2"
                : "bg-transparent"
            }  flex flex-wrap w-full items-center justify-between relative rounded-lg transition duration-300 md:py-2`}
          >
            <div
              className={`${
                changeColor || isExpanded ? "block md:hidden" : "hidden"
              } bg-primary absolute w-full h-full  rounded-lg`}
              ref={toExpandRef}
            ></div>

            <div className="relative flex w-2/3 md:w-1/5 items-center ">
              <Link to="/">
                <div className="flex items-start text-gray-900 no-underline">
                  <h2 className="  flex">
                    <Logo
                      className={`block h-6  w-auto fill-current ${
                        isExpanded ? "text-white" : "text-primary"
                      }`}
                      alt="Trame Digitali - Jam Stack Web Agency"
                    />
                  </h2>

                  {/* <span
                  className={`${
                    isExpanded ? "text-white" : "text-primary"
                  } text-xl font-bold tracking-tight ml-1`}
                >
                  {site.siteMetadata.title}
                </span> */}
                </div>
              </Link>
            </div>
            <div className="hidden md:flex md:justify-center md:w-3/5">
              <nav
                className={`${isExpanded ? `block` : `hidden`} ${
                  changeColor ? "text-black" : "text-black"
                } md:flex md:justify-center md:items-center w-full md:w-auto`}
              >
                {ROUTES.map((link) => (
                  <Link
                    className="block mt-4 font-semibold no-underline md:inline-block md:mt-0 md:ml-8"
                    key={link.title}
                    to={link.route}
                    onClick={() => {
                      if (storageAvailable) {
                        localStorage.setItem("last-post", lastPost.id);
                        setShowBlogNotification(false);
                      }
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <Text color={changeColor ? "black" : color}>
                        {link.title}
                      </Text>

                      {link.route === "/blog" && showBlogNotification && (
                        <div className="pb-4">
                          <Lottie
                            options={notificationOptions}
                            width={15}
                            height={15}
                            speed={0.5}
                            isStopped={false}
                            isPaused={false}
                          />
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
              </nav>
            </div>
            <div className="relative flex w-1/3 md:w-1/5 justify-end ">
              <div className={`hidden ${changeColor ? "" : "md:flex"}`}>
                <Button typeformId="qT0c2QIo" inverted>
                  Ti va un caffè?
                </Button>
              </div>
              <button
                className={`items-center block text-white rounded md:hidden rounded-full bg-white ${
                  changeColor ? "" : "shadow-lg active:shadow-md"
                }`}
                onClick={toggleMenuTl}
                id="test"
              >
                <div className="flex items-center relative">
                  <Lottie
                    options={hamburgerOptions}
                    width={40}
                    height={40}
                    speed={speed}
                    ref={hamburgerRef}
                    isStopped={isStopped}
                    isPaused={isPaused}
                  />
                  {showBlogNotification && !isExpanded && (
                    <div className="pb-4 absolute right-0">
                      <Lottie
                        options={notificationOptions}
                        width={30}
                        height={30}
                        speed={0.5}
                        isStopped={false}
                        isPaused={false}
                      />
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </header>
      </>
    );
  }
);

export default Header;
