import React, { useState } from "react";
import PropTypes from "prop-types";

const Label = ({ color, name, number }) => {
  const [hover, setHover] = useState(false);

  const shadow = "-4px 4px black";
  const hoverShadow = `-6px 6px ${color}`;

  return (
    <span
      className={`text-xs p-0.5 px-2 bg-white rounded-md w-min border-2 transition-all duration-300 whitespace-nowrap`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        borderColor: hover ? color : "black",
        color: hover ? color : "black",
        boxShadow: hover ? hoverShadow : shadow,
      }}
    >
      {name}
      {number && (
        <span className="ml-2 rounded-full px-2  text-xxs bg-black text-white">
          {number}
        </span>
      )}
    </span>
  );
};

Label.defaultProps = {
  color: "#000000",
  name: "Altro",
};

Label.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
export default Label;
