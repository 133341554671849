import React, { useLayoutEffect, forwardRef } from "react";
import { Link } from "gatsby";
import videoDuomo from "images/videos/duomo-test-3.small.ffmpeg.webm";
import videoKilo from "images/videos/300_kiloveste.webm";
import videoIeT from "images/videos/150_indiaetessa.webm";
import videoMarty from "images/videos/300_marty-02.webm";
import videoAlpi from "images/videos/300_alpi.webm";
import videoUfip from "images/videos/130_ufip.webm";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// const videoDuomo = loadable(() =>
//   import("../../images/videos/ilgrandemuseodelduomo.webm")
// );

// const videoKilo = loadable(() =>
//   import("../../images/videos/kiloveste-01.webm")
// );

// const videoIeT = loadable(() =>
//   import("../../images/videos/indiaetessa-02.webm")
// );

// const videoMarty = loadable(() => import("../../images/videos/marty-01.webm"));

// const videoAlpi = loadable(() => import("../../images/videos/alpiapp.webm"));

// const videoUfip = loadable(() => import("../../images/videos/ufip-01.webm"));

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals("ScrollTrigger", ScrollTrigger);

// eslint-disable-next-line
const AnimatedPortfolio = forwardRef((props, ref) => {
  useLayoutEffect(() => {
    if (!ref) return;
    const el = ref.current;

    if (!el) return;
    const pinner = el.querySelector("#pinwhilescroll");
    const starter = el.querySelector("#start");
    const videos = el.querySelectorAll("video");
    const topLeft = el.querySelector("#top-left");
    const topRight = el.querySelector("#top-right");
    const centerRight = el.querySelector("#center-right");
    const centerLeft = el.querySelector("#center-left");
    const bottomRight = el.querySelector("#bottom-right");
    const bottomLeft = el.querySelector("#bottom-left");
    const topRightMobile = el.querySelector("#top-right-mobile");
    const topLeftMobile = el.querySelector("#top-left-mobile");
    const bottomRightMobile = el.querySelector("#bottom-right-mobile");
    const bottomLeftMobile = el.querySelector("#bottom-left-mobile");
    const bottomCenterMobile = el.querySelector("#bottom-center-mobile");
    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({
      "(max-width: 799px)": function () {
        const pinTl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            pin: pinner,
            // markers: false,
            start: "top top",
            pinSpacing: true,
            end: "+=300px",
            scrub: true,
          },
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            // pin: true,
            start: "top top",
            // pinSpacing: true,
            scrub: true,
            // yoyo: true,
            end: "+=300px",
          },
          onComplete: () => {
            try {
              videos?.forEach((video) => video.play());
            } catch (e) {
              console.log(e);
            }
          },
          // onReverseComplete: () => videos?.forEach((video) => video.pause()),
        });

        tl.fromTo(
          topLeftMobile,
          {
            autoAlpha: 0,
            xPercent: -100,
            yPercent: -450,
          },
          {
            autoAlpha: 1,
            xPercent: -50,
            yPercent: -220,
          },
          0
        );

        tl.fromTo(
          topRightMobile,
          {
            autoAlpha: 0,
            xPercent: 200,
            yPercent: -200,
          },
          {
            autoAlpha: 1,
            xPercent: 100,
            yPercent: -150,
          },
          0
        );
        tl.fromTo(
          bottomLeftMobile,
          {
            autoAlpha: 0,
            yPercent: 250,
            xPercent: -40,
          },
          {
            autoAlpha: 1,
            xPercent: 250,
            yPercent: -130,
          },
          0
        );

        tl.fromTo(
          bottomRightMobile,
          {
            autoAlpha: 0,
            yPercent: -30,
            xPercent: 40,
          },
          {
            autoAlpha: 1,
            xPercent: 120,
            yPercent: 420,
          },
          0
        );

        tl.fromTo(
          bottomCenterMobile,
          {
            autoAlpha: 0,
            xPercent: -40,
          },
          {
            autoAlpha: 1,
            xPercent: 0,
            yPercent: 430,
            rotation: 0.01,
            z: 0.01,
          },
          0
        );
      },
      "(min-width:800px) and (max-width: 1919px)": function () {
        const pinTl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            pin: pinner,
            // markers: false,
            start: "top top",
            pinSpacing: true,
            end: "+=500",
            scrub: true,
          },
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            markers: false,
            // pin: pinner,
            start: "top top",
            // pinSpacing: true,
            end: "+=300",
            scrub: true,
          },
          onComplete: () => {
            try {
              videos?.forEach((video) => video.play());
            } catch (e) {
              console.log(e);
            }
          },
          // onReverseComplete: () => videos?.forEach((video) => video.pause()),
        });

        // kilo
        tl.fromTo(
          bottomRight,
          {
            autoAlpha: 1,
            yPercent: -300,
            xPercent: -70,
          },
          {
            autoAlpha: 1,
            xPercent: -130,
            yPercent: 230,
          },
          0
        );

        // marty
        tl.fromTo(
          topRight,
          {
            autoAlpha: 1,
            yPercent: -330,
            xPercent: 190,
          },
          {
            autoAlpha: 1,
            xPercent: 130,
            yPercent: 210,
          },
          0
        );
        // duomo
        tl.fromTo(
          topLeft,
          {
            autoAlpha: 1,
            xPercent: -190,
            yPercent: -330,
          },
          {
            autoAlpha: 1,
            yPercent: 100,
            xPercent: -140,
          },
          0
        );

        // india
        tl.fromTo(
          centerRight,
          {
            autoAlpha: 1,
            yPercent: -380,
            xPercent: 550,
          },
          {
            autoAlpha: 1,
            xPercent: 270,
            yPercent: 70,
          },
          0
        );

        // ufip
        tl.fromTo(
          bottomLeft,
          {
            autoAlpha: 1,
            yPercent: -380,
            xPercent: -550,
          },
          {
            autoAlpha: 1,
            xPercent: -250,
            yPercent: -25,
          },
          0
        );

        // alpi
        tl.fromTo(
          centerLeft,
          {
            autoAlpha: 1,
            xPercent: 70,
            yPercent: -310,
          },
          {
            autoAlpha: 1,
            xPercent: 120,
            yPercent: -50,
            rotation: 0.01,
            z: 0.01,
          },
          0
        );
      },
      "(min-width: 1920px)": function () {
        const pinTl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            pin: pinner,
            markers: false,
            start: "top top",
            pinSpacing: true,
            end: "+=500",
            scrub: true,
          },
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: starter,
            // pin: pinner,
            markers: false,
            start: "top top",
            // pinSpacing: true,
            end: "+=300",
            scrub: true,
          },
          onComplete: () => {
            try {
              videos?.forEach((video) => video.play());
            } catch (e) {
              console.log(e);
            }
          },
          // onReverseComplete: () => videos?.forEach((video) => video.pause()),
        });

        tl.fromTo(
          topLeft,
          {
            autoAlpha: 1,
            xPercent: -100,
            yPercent: -260,
          },
          {
            autoAlpha: 1,
            yPercent: 65,
            xPercent: -180,
          },
          0
        );

        tl.fromTo(
          topRight,
          {
            autoAlpha: 1,
            yPercent: -300,
            xPercent: 190,
          },
          {
            autoAlpha: 1,
            xPercent: 160,
            yPercent: 190,
          },
          0
        );

        // IET
        tl.fromTo(
          centerRight,
          {
            autoAlpha: 1,
            yPercent: -410,
            xPercent: 650,
          },
          {
            autoAlpha: 1,
            xPercent: 280,
            yPercent: 55,
          },
          0
        );

        // UFIP
        tl.fromTo(
          bottomLeft,
          {
            autoAlpha: 1,
            yPercent: -220,
            xPercent: 200,
          },
          {
            autoAlpha: 1,
            xPercent: -280,
            yPercent: -50,
          },
          0
        );

        // KILO
        tl.fromTo(
          bottomRight,
          {
            autoAlpha: 1,
            yPercent: -300,
            xPercent: -250,
          },
          {
            autoAlpha: 1,
            xPercent: -150,
            yPercent: 180,
          },
          0
        );

        // alpi
        tl.fromTo(
          centerLeft,
          {
            autoAlpha: 1,
            xPercent: -300,
            yPercent: -500,
          },
          {
            autoAlpha: 1,
            xPercent: 150,
            yPercent: -60,
            rotation: 0.01,
            z: 0.01,
          },
          0
        );
      },
    });
  });

  return (
    <>
      {/* Start mobile devices animations */}
      <div
        className="block md:hidden absolute bg-white h-40 w-20 z-20 rounded border-2 border-black shadow-video"
        id="top-right-mobile"
      >
        <Link to="/portfolio/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoIeT}></source>
            </video>
          </a>
        </Link>
      </div>

      <div
        className="block md:hidden absolute bg-white h-20 w-40 z-20 rounded border-2 border-black shadow-video"
        id="top-left-mobile"
      >
        <Link to="/clienti/kiloveste/">
          <a>
            <video className="w-full h-full object-cover rounded " muted loop>
              <source src={videoKilo}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="block md:hidden absolute  bg-white h-28 w-44 z-20 border-2 border-white rounded shadow-video"
        id="bottom-center-mobile"
      >
        <Link to="/clienti/marty/">
          <a>
            <video className="w-full h-full object-cover " muted loop>
              <source src={videoMarty}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="block md:hidden  absolute  bg-white h-24 w-44  z-20 rounded border-2 border-black shadow-video "
        id="bottom-right-mobile"
      >
        <Link to="/clienti/albini-e-pitigliani/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoAlpi}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="block md:hidden absolute  bg-white h-36 w-20 z-20 rounded border-2 border-black p-px shadow-video"
        id="bottom-left-mobile"
      >
        <Link to="/clienti/ufip-cymbals/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoUfip}></source>
            </video>
          </a>
        </Link>
      </div>

      {/* End mobile devices animations */}

      {/* Start desktop devices animations */}
      <div
        className="hidden md:block absolute bg-white desktop-video z-20 rounded border-2 border-black p-1 transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="top-right"
      >
        <Link to="/clienti/marty/">
          <a>
            <video className="w-full h-full object-cover rounded " muted loop>
              <source src={videoMarty}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="hidden md:block absolute  bg-white desktop-video z-20 rounded border-2 border-black p-1 transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="top-left"
      >
        <Link to="/clienti/il-grande-museo-del-duomo/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoDuomo}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="hidden md:block absolute  bg-white mobile-video z-20  rounded border-2 border-black transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="center-right"
      >
        <Link to="/portfolio/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoIeT}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="hidden md:block absolute  bg-white desktop-video z-20 rounded border-2 border-black transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="center-left"
      >
        <Link to="/clienti/albini-e-pitigliani/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoAlpi}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="hidden md:block absolute  bg-white mobile-video z-20 rounded border-2 border-black p-1 transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="bottom-left"
      >
        <Link to="/clienti/ufip-cymbals/">
          <a>
            <video className="w-full h-full object-cover  rounded" muted loop>
              <source src={videoUfip}></source>
            </video>
          </a>
        </Link>
      </div>
      <div
        className="hidden md:block absolute  bg-white desktop-video z-20 border-2 border-black rounded p-1 transition-all duration-300 shadow-videoShort hover:shadow-video "
        id="bottom-right"
      >
        <Link to="/clienti/kiloveste/">
          <a>
            <video className="w-full h-full object-cover " muted loop>
              <source src={videoKilo}></source>
            </video>
          </a>
        </Link>
      </div>
      {/* End desktop devices animations */}
    </>
  );
});

export default AnimatedPortfolio;
