import React, { forwardRef, useRef } from "react";
import { Button, ReadMore, Text, Title } from "components";
import { StaticImage } from "gatsby-plugin-image";
import {
  WORK_WITH_US,
  SERVICES,
  AGENCY,
  CASE_STUDIES,
} from "../../helpers/route";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
const Footer = forwardRef((props, ref) => {
  const {
    allContentfulJob: { totalCount: jobs },
  } = useStaticQuery(FOOTER_QUERY);
  // useEffect(() => {
  //   const e = document.querySelector("#highlight-text-footer");
  //   const annotation = annotate(e, {
  //     type: "circle",
  //     color: "#ff1d4d",
  //     strokeWidth: 8,
  //     padding: 10,
  //     animationDuration: 2000,
  //   });

  //   const c = document.querySelector("#highlight-text-footer");
  //   const secondAnnotation = annotate(c, {
  //     type: "circle",
  //     color: "#ff1d4d",
  //     strokeWidth: 3,
  //     padding: 10,
  //     animationDuration: 2000,
  //   });
  //   annotation.show();
  //   secondAnnotation.show();
  // }, []);
  const toHighlightRef = useRef();
  return (
    <footer
      className="z-20 relative w-screen text-left bg-black pt-8"
      ref={ref}
      // style={{ height: "75vh" }}
    >
      <section className="pt-40 md:pt-20 pb-4">
        <div className="mx-auto px-8 sm:px-6  lg:px-32 h-full locontainer max-w-6xl flex flex-col justify-center items-center md:items-start">
          <div className="md:w-2/3">
            <Title color="white" sRize="small" margin={false}>
              Resta in ascolto che c&apos;è un messaggio per te!
            </Title>
            <div className="pt-2 flex flex-row justify-start items-center">
              <div className="my-2 ml-2">
                <Button bigger typeformId={"dCUKWHVh"}>
                  Iscriviti alla newsletter!
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col hidden">
          <span className="z-1  text-lg text-black text-left py-1 px-2 leading-loose">
            Chase guns holystone heave
          </span>
          <span className="z-1  text-lg text-black text-left py-1 px-2 leading-loose">
            coxswain jib scallywag.
          </span>
          <span className="z-1  text-lg  text-black text-left py-1 px-2 leading-loose">
            account quarterdeck wench
          </span>
        </div>
      </section>
      <section className="relative z-10">
        <div className="max-w-6xl mx-auto py-4 px-4 sm:px-6  lg:px-32 flex-col flex">
          <div className="text-white grid grid-cols-2 lg:grid-cols-5 gap-x-4 gap-y-4 md:gap-8 py-4 px-4">
            <div>
              <Text size="small" opaque color="white">
                Agenzia
              </Text>
              <ul className="pt-2 space-y-1">
                {AGENCY.map(({ route, title }, index) => (
                  <li key={index}>
                    <Text color="white" size="small">
                      <Link
                        to={route}
                        className="text-sm hover:opacity-40 break-words"
                      >
                        {title}
                      </Link>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
            <div className="grid grid-cols-1">
              {/* <h3 className="text-xs font-light opacity-70 tracking-wider">
                Servizi
              </h3> */}
              <Text size="small" opaque color="white">
                Servizi
              </Text>
              <ul className="pt-2 space-y-1">
                {SERVICES.map(({ route, title }, index) => (
                  <li key={index}>
                    <Text color="white" size="small">
                      <Link
                        to={route}
                        className="text-sm hover:opacity-40 break-words"
                      >
                        {title}
                      </Link>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
            <div className="hidden lg:block">
              <Text size="small" opaque color="white">
                Lavori
              </Text>
              <ul className="pt-2 space-y-1">
                {CASE_STUDIES.map(({ route, title }, index) => (
                  <li key={index}>
                    <Text color="white" size="small">
                      <Link
                        to={route}
                        className="text-sm hover:opacity-40 break-words"
                      >
                        {title}
                      </Link>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
            <div className="grid grid-cols-2">
              <div className="lg:hidden">
                <Text size="small" opaque color="white">
                  Lavori
                </Text>
                <ul className="pt-2 space-y-1">
                  {CASE_STUDIES.map(({ route, title }, index) => (
                    <li key={index}>
                      <Text color="white" size="small">
                        <Link
                          to={route}
                          className="text-sm hover:opacity-40 break-words"
                        >
                          {title}
                        </Link>
                      </Text>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="hidden md:block pt-12 md:pt-0 col-span-2">
                <Text size="small" opaque color="white">
                  Opportunità lavorative
                </Text>
                <ul className="pt-2 space-y-1">
                  <li>
                    <div className="flex flex-col">
                      <Text color="white" size="small">
                        <Link to="/lavora-con-noi/">Lavora con noi</Link>
                      </Text>
                      <Link to="/lavora-con-noi/">
                        <div className="border-white mt-2 border-2 p-2 px-3 rounded flex items-center justify-start shadow-button">
                          {jobs > 0 && (
                            <Text color="white" size="small">
                              Abbiamo{" "}
                              <span className="mx-1 px-2 bg-white text-black rounded-full font-black">
                                {jobs}
                              </span>{" "}
                              {`posizion${jobs > 1 ? "i" : "e"} 
                                  apert${jobs > 1 ? "e" : "a"}`}
                            </Text>
                          )}
                        </div>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="block md:hidden grid grid-cols-2">
              <div className="md:pt-0 col-span-2">
                <Text size="small" opaque color="white">
                  Jobs
                </Text>
                <ul className="pt-2 space-y-1">
                  <li>
                    <div className="flex flex-col">
                      <Text color="white" size="small">
                        <Link to="/lavora-con-noi/">Lavora con noi</Link>
                      </Text>

                      <div className="border-white mt-2 border-2 p-2 px-3 rounded flex items-center justify-start shadow-button">
                        <Link to="/lavora-con-noi/">
                          {jobs > 0 && (
                            <Text color="white" size="small">
                              Abbiamo{" "}
                              <span className="mx-1 px-2 bg-white text-black rounded-full font-black">
                                {jobs}
                              </span>{" "}
                              {`posizion${jobs > 1 ? "i" : "e"} 
                                apert${jobs > 1 ? "e" : "a"}`}
                            </Text>
                          )}
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="px-4 text-white pt-1 flex flex-col lg:flex-row space-y-3 lg:space-y-0 items-center justify-center lg:justify-between">
            <div className="lg:order-2 text-left md:text-center font-light flex-grow text-sm opacity-70 w-full sm:w-auto">
              <div>
                <Text color="white" size="smaller">
                  Trame Digitali&reg; è un marchio registrato da Nexha SRL, Via Frà Bartolomeo 82/A, Prato. P.Iva: 02450300971 - Credits
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="block md:hidden absolute right-0 top-0 z-0 ">
        <StaticImage
          placeholder="tracedSVG"
          formats={["webp", "avif", "png"]}
          src={"../../images/hands-horiz-resized.png"}
          alt="two hands tied with a red line"
          className="w-full h-auto"
          objectFit="contain"
        />
      </div>
      <div className="hidden md:block absolute h-full right-0 bottom-0 z-0 ">
        <StaticImage
          placeholder="tracedSVG"
          formats={["webp", "avif", "png"]}
          src={"../../images/hands-resized.png"}
          alt="two hands tied with a red line"
          className="h-full w-auto"
          objectFit="contain"
        />
      </div>
    </footer>
  );
});

const FOOTER_QUERY = graphql`
  query GetJobs {
    allContentfulJob(filter: { node_locale: { eq: "it" } }) {
      totalCount
    }
  }
`;

export default Footer;
