import React from "react";
import PropTypes from "prop-types";
import { Title, Text } from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Intro = ({
  beforeTitle,
  title,
  subtitle,
  description,
  image,
  color,
  children,
  headingLevel,
}) => {
  const pImage = image ? getImage(image) : undefined;
  const heading = headingLevel ? headingLevel : "h1";

  return (
    <div className="z-30 relative flex text-left flex-1 overflow-hidden">
      {/* <div className="absolute z-10 w-full h-screen">
              <img className="w-full h-full object-cover" src={team}></img>
            </div> */}
      <div className=" text-black mb-12 mt-32 md:mt-44 flex-1 md:mt-1 z-30 flex flex-col md:pr-24">
        {/* <div className={`${description ? "md:w-3/6" : ""}`}> */}
        <div className="">
          {beforeTitle && (
            <Text opaque size="small">
              {beforeTitle}
            </Text>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col">
            <Title
              weight="black"
              size={"hero"}
              color={color}
              headingLevel={heading}
            >
              {title}
            </Title>
            {subtitle && (
              <Text opaque size="small">
                {subtitle}
              </Text>
            )}
          </div>
          {description && (
            <div className="my-4 md:my-0 block md:w-96">
              <Text>{description}</Text>
            </div>
          )}
          {!description && image && pImage && (
            <div className="hidden md:block w-44">
              <GatsbyImage image={pImage} alt={title} />
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

Intro.defaultProps = {
  title: "Titolo pagina",
  description: "",
  subtitle: "",
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Intro;
