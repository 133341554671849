import React from "react";
import PropTypes from "prop-types";
import { RoughNotation } from "react-rough-notation";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useInView } from "react-intersection-observer";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const NormalText = ({
  children,
  center,
  right,
  opaque,
  size,
  weight,
  color,
  uppercase,
  disableAnimation,
}) => {
  const text = (
    <span
      className={`block utility-cl font-sans_text tracking-tight 2xl:tracking-normal leading-relaxed
        ${center ? " text-center" : ""}
        ${right ? " text-right" : ""}
        ${opaque ? "opacity-40" : ""}
        ${size === "base" ? "text-base md:text-lg xl:text-xl 2xl:text-2xl" : ""}
        ${size === "small" ? "text-sm" : ""}
        ${size === "smaller" ? "text-xs" : ""}
        ${size === "smallest" ? "text-xxs" : ""}
        ${size === "blog" ? "text-blog leading-blog" : ""}
        ${color === "primary" ? "text-primary" : ""}
        ${color === "secondary" ? "text-black" : ""}
        ${color === "black" ? "text-black" : ""}
        ${color === "white" ? "text-white" : ""}
        ${weight === "regular" ? "" : ""}
        ${weight === "bold" ? "font-bold" : ""}
        ${weight === "black" ? "font-black" : ""}
        ${uppercase ? "uppercase" : ""}`}
    >
      {children}
    </span>
  );

  if (disableAnimation) return text;
  return (
    <Reveal keyframes={customAnimation} triggerOnce>
      {text}
    </Reveal>
  );
};

const HighlightedText = ({
  children,
  center,
  right,
  opaque,
  size,
  weight,
  color,
  highlight,
}) => {
  const { ref, inView, entry } = useInView();

  return (
    <RoughNotation
      show={inView}
      multiline={true}
      type="underline"
      color="#ff1d4d61"
      iterations={1}
      padding={2}
      strokeWidth="2"
    >
      <span
        ref={ref}
        className={`utility-cl font-sans_text tracking-tight 2xl:tracking-normal leading-tight
      ${center ? " text-center" : ""}
      ${right ? " text-center" : ""}
      ${opaque ? "opacity-40" : ""}
      ${size === "base" ? "text-base md:text-lg xl:text-xl 2xl:text-2xl" : ""}
      ${size === "small" ? "text-sm" : ""}
      ${size === "smaller" ? "text-xs" : ""}
      ${size === "smallest" ? "text-xxs" : ""}
      ${color === "primary" ? "text-primary" : ""}
      ${color === "secondary" ? "text-black" : ""}
      ${color === "black" ? "text-black" : ""}
      ${color === "white" ? "text-white" : ""}
      ${weight === "regular" ? "" : ""}
      ${weight === "bold" ? "font-bold" : ""}
      ${weight === "black" ? "font-black" : ""}`}
      >
        {children}
      </span>
    </RoughNotation>
  );
};

const Text = (props) => {
  // this distinction is only to avoid instatiating 129312458123 ref for intersection observer
  if (props?.highlight) return <HighlightedText {...props} />;
  return <NormalText {...props}></NormalText>;
};

Text.defaultProps = {
  size: "base",
  color: "black",
  weight: "regular",
  opaque: false,
  children: "",
  center: false,
  right: false,
  disableAnimation: false,
};

Text.propTypes = {
  size: PropTypes.oneOf([
    "smallest",
    "smaller",
    "small",
    "base",
    "large",
    "xl",
    "hero",
  ]),
  color: PropTypes.oneOf(["primary", "secondary", "black", "white"]),
  weight: PropTypes.oneOf(["regular", "bold", "black"]),
  opaque: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  disableAnimation: PropTypes.bool,
};

export default Text;
