export const ROUTES = [
  {
    route: `/chi-siamo/`,
    title: `Chi siamo`,
  },
  {
    route: `/portfolio/`,
    title: `Portfolio`,
  },
  {
    route: `/come-lavoriamo/`,
    title: `Come lavoriamo`,
  },
  {
    route: `/blog/`,
    title: `Pillole`,
  },
  {
    route: `/contattaci/`,
    title: `Contattaci`,
  },
];
export const AGENCY = [
  {
    route: `/chi-siamo/`,
    title: `Chi siamo`,
  },
  {
    route: `/come-lavoriamo/`,
    title: `Come lavoriamo`,
  },
  {
    route: `/blog/`,
    title: `Blog`,
  },
  {
    route: `/contattaci/`,
    title: `Contattaci`,
  },
];
export const SERVICES = [
  {
    route: `/realizzazione-siti-web/`,
    title: `Realizzazione siti web e ecommerce`,
  },
  {
    route: `/realizzazione-app-mobile/`,
    title: `Realizzazione app mobile`,
  },
  {
    route: `/seo-posizionamento-sui-motori-di-ricerca/`,
    title: `SEO`,
  },
  {
    route: `/web-marketing-analytics/`,
    title: `Web Marketing`,
  },
];
export const CASE_STUDIES = [
  {
    route: `/portfolio/`,
    title: `Portfolio`,
  },
  {
    route: `/portfolio/`,
    title: `Case studies`,
  },
];

export const WORK_WITH_US = [
  {
    route: `/lavora-con-noi/`,
    title: `Lavora con noi`,
  },
];
