import React, { useRef, useState, useEffect } from "react";
import { Subtitle, Text } from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HOW_MUCH_X_PERCENT_TO_MOVE = [0, 0, -54, -54];

const FoldingTeam = ({ people }) => {
  const [free, setFree] = useState(true);
  const tweenRef = useRef();
  const moveDivRef = useRef();
  const [hovered, setHovered] = useState(null);
  const timelinesShadow = [];
  const timelinesMove = [];

  const shadowRefs = useRef([]);
  shadowRefs.current = [];

  const moveRefs = useRef([]);
  moveRefs.current = [];

  const onMouseEnterHandler = (key) => {
    timelinesMove.map((tl, index) => index !== key && tl.play());
    if (timelinesShadow[key]) {
      timelinesShadow[key].play();
    }
  };

  const onMouseLeaveHandler = (key) => {
    if (timelinesShadow[key]) timelinesShadow[key].reverse();
    setTimeout(() => {
      timelinesMove.map((tl, index) => index !== key && tl.reverse());
    }, 900);
  };

  const addToShadowRefs = (el) => {
    if (!shadowRefs.current.includes(el)) {
      shadowRefs.current.push(el);
    }
  };

  const addToMoveRefs = (el) => {
    if (!moveRefs.current.includes(el)) {
      moveRefs.current.push(el);
    }
  };

  useEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 800px)": function () {
        shadowRefs.current.forEach((el, index) => {
          const tlShadow = gsap.timeline({ paused: true });

          tlShadow
            .to(el, {
              width: "46vw",
              xPercent: HOW_MUCH_X_PERCENT_TO_MOVE[index],
              duration: 0.5,
              ease: "Power3.easeInOut",
            })
            .from(el.querySelector(".text-to-show"), {
              autoAlpha: 0,
            })
            .to(el.querySelector(".text-to-show"), {
              autoAlpha: 1,
            });

          timelinesShadow.push(tlShadow);
        });

        moveRefs.current.forEach((el, index) => {
          const tlMove = gsap.timeline({ paused: true });
          tlMove
            .to(el.querySelectorAll("span"), {
              autoAlpha: 0,
            })
            .to(el, {
              duration: 0.5,
              xPercent: (3 - index) * 100,
              ease: "Power3.easeInOut",
              autoAlpha: 0,
            });

          timelinesMove.push(tlMove);
        });
      },
    });
  }, []);

  return people.map((person, key) => {
    const image = getImage(person?.avatar);

    return (
      <div
        ref={addToMoveRefs}
        key={key}
        className="flex flex-col p-4 pl-0 relative mr-4"
      >
        <div
          className={`absolute bg-primary z-10 rounded border-4 border-black flex w-18-h-30-shadow
                    ${key > 1 ? "justify-start" : "justify-end"}`}
          ref={addToShadowRefs}
        >
          <div
            className={`text-to-show invisible py-3 px-4 w-1/2  flex flex-col`}
          >
            <Subtitle white>{person.name}</Subtitle>
            <Text color="white" size="small">
              {person?.description?.description}
            </Text>
          </div>
        </div>

        <GatsbyImage
          key={key}
          objectFit="cover"
          image={image}
          className="object-cover z-20 rounded border-4 border-black w-19-h-30"
          onMouseEnter={() => onMouseEnterHandler(key)}
          onMouseLeave={() => onMouseLeaveHandler(key)}
          alt={`${people?.name} foto`}
        />

        {/* <img
          className="object-cover z-20 rounded border-4 border-black"
          style={{ width: "19vw", height: "30vh" }}
          src={person.avatar.file.url}
          onMouseEnter={() => onMouseEnterHandler(key)}
          onMouseLeave={() => onMouseLeaveHandler(key)}
        ></img> */}
        <span className="mt-8 font-semibold text-2xl text-justify tracking-tight">
          {person.name}
        </span>
        <Text>{person.position}</Text>
      </div>
    );
  });
};

export default FoldingTeam;
