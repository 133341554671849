import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const ReadMore = ({ children, black, transparent, white, opaque }) => {
  return (
    <div className="cta_container">
      <button
        className={`
                cta py-4 rounded flex justify-center items-center 
                ${black ? "text-white" : ""}
                ${transparent ? "bg-transparent text-black" : ""}
                ${white ? " text-black" : ""}
        `}
      >
        <span className="button-text font-normal">{children}</span>
        <FontAwesomeIcon icon={faChevronRight} className="w-2" />
      </button>
    </div>
  );
};

ReadMore.defaultProps = {
  children: "Scopri di più",
  black: false,
  transparent: false,
  white: false,
};

ReadMore.propTypes = {
  // children: PropTypes.oneOfType([
  //   PropTypes.string.isRequired,
  //   PropTypes.array.isRequired
  // ]),
  black: PropTypes.bool,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
};

export default ReadMore;
