import React from "react";
import GoogleMapReact from "google-map-react";
import { Title, Text } from "components";
import MarkerSVG from "images/svg/marker.svg";

const styles = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#00ff48",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#00ffaa",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#0048ff",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [
      {
        hue: "#8fff00",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        color: "#b0a9a9",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#c7adad",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        hue: "#00aaff",
      },
      {
        visibility: "on",
      },
    ],
  },
];

const AnyReactComponent = ({ text }) => (
  <div className="">
    <MarkerSVG className="w-16 h-16"></MarkerSVG>
    {/* <Text>Trame Digitali</Text> */}
  </div>
);

export default function Map() {
  const defaultProps = {
    center: {
      lat: 43.8730951,
      lng: 11.1080566,
    },
    zoom: 15,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBHcGx6Q7Ha6EUwjQuEDAWTUpENFw0Vmiw",
          libraries: ["places", "visualization"],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles,
          //   styles: [
          //     {
          //       featureType: "all",
          //       elementType: "labels",
          //       stylers: [{ visibility: "on" }],
          //     },
          //   ],
        }}
      >
        <AnyReactComponent lat={43.8730951} lng={11.1080566} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}
