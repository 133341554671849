import React from "react";
import PropTypes from "prop-types";

const TimelineCardItemLeft = ({ title, description }) => {
  return (
    <div className="flex flex-row-reverse md:contents ">
      <div className="col-start-1 col-end-5 p-4 rounded-md my-4 ml-auto border-3 border-black border-solid shadow-button">
        <h3 className="font-semibold text-lg text-black mb-1">{title}</h3>
        <p className="leading-tight text-black text-justify">{description}</p>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-1 bg-primary pointer-events-none"></div>
        </div>
        <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-primary shadow"></div>
      </div>
    </div>
  );
};
const TimelineCardItemRight = ({ title, description }) => {
  return (
    <div className="flex md:contents">
      <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-1 bg-primary pointer-events-none"></div>
        </div>
        <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-primary shadow"></div>
      </div>
      <div className="col-start-6 col-end-10 p-4 rounded-md my-4 mr-auto border-3 border-black border-solid shadow-button">
        <h3 className="font-semibold text-lg text-black mb-1">{title}</h3>
        <p className="leading-tight text-black text-justify">{description}</p>
      </div>
    </div>
  );
};

const Timeline = ({ cards }) => {
  return (
    <div className="container">
      <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-red-50">
        {cards.map(({ title, description, position }, index) =>
          position === "left" ? (
            <TimelineCardItemLeft title={title} description={description} />
          ) : (
            <TimelineCardItemRight title={title} description={description} />
          )
        )}
      </div>
    </div>
  );
};

export default Timeline;
