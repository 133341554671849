import React, { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { annotate } from "rough-notation";

const HighlightedText = forwardRef(
  ({ size, white, className, children }, ref) => {
    useEffect(() => {
      if (!ref || !ref.current) return;

      const toHighlight = ref.current;
      const annotation = annotate(toHighlight, {
        type: "underline",
        color: "#ff1d4d",
        strokeWidth: 5,
        iterations: 3,
        padding: 3,
        animationDuration: 3000,
      });

      annotation.show();
    }, []);

    return (
      <span
        className={`text-center tracking-tighter
        ${white ? "text-white" : "text-black"}
        ${
          size === "base" ? "text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl" : ""
        }
        ${
          size === "small"
            ? "text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-semibold"
            : ""
        }
        ${size === "large" ? "" : ""}
        ${size === "xl" ? "" : ""}
        ${size === "hero" ? "text-5xl md:text-8xl 2xl:text-8xl font-black" : ""}
         w-full block  ${className}`}
        ref={ref}
      >
        {children}
      </span>
    );
  }
);

HighlightedText.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  white: PropTypes.bool,
  size: PropTypes.oneOf(["small", "base", "large", "xl", "hero"]),
};

HighlightedText.defaultProps = {
  children: "Evidenziato",
  className: "",
  size: "base",
  white: false,
};

export default HighlightedText;
