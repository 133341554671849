import React from "react";
import { Text } from "components";
const ContactForm = ({ action }) => {
  return (
    <div className="  px-8 grid gap-8 grid-cols-1  md:px-16 lg:px-20  py-8  text-gray-900 rounded-lg">
      <div className="">
        <form action={action} method="POST">
          <input type="hidden" name="_recaptcha" id="_recaptcha" />
          <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"></script>
          <script src="https://www.flexyform.com/js/recaptcha.js"></script>

          <div>
            <div className="text-gray-700 mt-16 md:mt-0 md:mb-8">
              <Text>
                Odi i form? Scrivici a{" "}
                <a href="mailto:hey@trame-digitali.it" className="underline">
                  hey@trame-digitali.it
                </a>
              </Text>
            </div>
            <span className="uppercase text-sm font-bold">Nome e Cognome</span>
            <input
              className="w-full  mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Darth Fener"
              name="fullname"
            />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm font-bold">Email</span>
            <input
              className="w-full mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="email"
              name="_reply_to"
              placeholder="fener@deathstar.wars"
            />
          </div>
          <div>
            <span className="uppercase text-sm font-bold">Telefono</span>
            <input
              className="w-full mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="+39 3328529100"
              name="telefono"
            />
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm font-bold">Messaggio</span>
            <textarea
              className="w-full h-32 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              name="messaggio"
              placeholder="Ho un problema con il pianeta Alderaan, potete aiutarmi?"
            ></textarea>
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="uppercase text-sm font-bold tracking-wide text-black p-3 rounded-md w-full focus:outline-none focus:shadow-outline border-3 border-solid border-black shadow-button"
            >
              Invia Messaggio
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
