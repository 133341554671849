import React from "react";
import { Link } from "gatsby";
import { Text, Title, Label, Subtitle } from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import renderDate from "helpers/renderDate";

const HeroPreview = ({ slug, title, subtitle, category, image, author }) => (
  <Link to={`/${category?.slug}/${slug}/`}>
    <div className="flex flex-1">
      <div className="flex flex-1 flex-col">
        <div className="bg-red-100 h-[50vh] my-2 w-full relative">
          <div className="absolute top-40 left-8 z-30 flex flex-col p-2 w-5/6">
            <div className="md:pl-16">
              <div className="my-4">
                <Label {...category} />
              </div>
              <Title disableAnimation size={"xl"} color="white">
                {title}
              </Title>
              <Text disableAnimation opaque color="white" size="base">
                {subtitle?.subtitle}
              </Text>
              <div className="flex flex-row z-30 my-4">
                {author?.picture && (
                  <div className="mr-2">
                    <GatsbyImage
                      objectFit="cover"
                      image={getImage(author?.picture)}
                      className="h-8 w-8 rounded-full"
                      alt={`${title} image`}
                    />
                  </div>
                )}

                <div className="flex flex-col">
                  <Text disableAnimation color="white" size="small">
                    {author?.name ?? "Cosimo Zecchi"}
                  </Text>
                  <Text disableAnimation color="white" size="smaller" opaque>
                    {author?.tagline ?? "..."}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <GatsbyImage
            objectFit="cover"
            image={image}
            className="h-full w-full filter brightness-50"
            alt={`${title} image`}
          />
        </div>
      </div>
    </div>
  </Link>
);
const SmallPreview = ({ slug, title, subtitle, category, image, author }) => (
  <Link to={`/${category?.slug}/${slug}/`}>
    <div className="flex flex-1 group ">
      <div className="flex flex-1 flex-col">
        <div className="h-96 w-full relative">
          <div className="absolute bottom-4 left-8 z-30 flex flex-col p-2 w-5/6">
            <div className="">
              <div className="my-4">
                <Label {...category} />
              </div>
              <div className="h-44 opacity-90 group-hover:opacity-100 transition-all duration-300 ">
                <Title disableAnimation size={"small"} color="white">
                  {title}
                </Title>
                {/* <Text opaque color="white" size="small">
                  {subtitle?.subtitle}
                </Text> */}
              </div>
            </div>
            <div className="flex flex-row z-30 my-2 items-center">
              {author?.picture && (
                <div className="mr-2">
                  <GatsbyImage
                    objectFit="cover"
                    image={getImage(author?.picture)}
                    className="h-10 w-10 rounded-full "
                    alt={`${title} image`}
                  />
                </div>
              )}

              <div className="flex flex-col md:opacity-0 group-hover:opacity-100 transition-all duration-700">
                <Text disableAnimation color="white" size="small">
                  {author?.name ?? "Cosimo Zecchi"}
                </Text>
                <Text disableAnimation color="white" size="smaller" opaque>
                  {author?.tagline ?? "..."}
                </Text>
              </div>
            </div>
          </div>

          <GatsbyImage
            objectFit="cover"
            image={image}
            className="h-full w-full filter brightness-50"
            alt={`${title} image`}
          />
        </div>
      </div>
    </div>
  </Link>
);

const Preview = ({
  slug,
  title,
  subtitle,
  category,
  image,
  author,
  createdAt,
}) =>
  category && (
    <Link to={`/${category?.slug}/${slug}/`}>
      <div className="flex  rounded-lg">
        <div className="flex flex-1 flex-col">
          <div className="bg-red-100 h-60 2xl:h-80 mb-4 w-full relative shadow-invButton rounded-md border-3 border-black">
            <GatsbyImage
              objectFit="cover"
              image={image}
              className="h-full w-full rounded"
              alt={`${title} image`}
            />
          </div>
          <div className="hidden flex my-4 justify-start items-center">
            <div className="ml-2 mr-2">
              {/* <Text size="small" color="white">
                  | {post.category.name}
                </Text> */}
              <Label {...category} />
            </div>
            <div className="mr-4">
              <Text disableAnimation size="smaller" color="black" opaque>
                <Label name={renderDate(createdAt)} />
              </Text>
            </div>

            {/* <div className="">
                <Text size="small" color="white">
                  {readingTime} min di lettura
                </Text>
              </div> */}
          </div>
          <div className="px-2 mt-8 mb-4">
            <Subtitle big bold>
              {title}
            </Subtitle>
          </div>
          <div className="px-2 my-1">
            <Text disableAnimation size="small" opaque>
              {subtitle?.subtitle}
            </Text>
          </div>
          <div className="px-2 my-1">
            <div className="flex flex-row z-30 my-4">
              {author?.picture && (
                <div className="mr-2">
                  <GatsbyImage
                    objectFit="cover"
                    image={getImage(author?.picture)}
                    className="h-8 w-8 rounded-full"
                    alt={`${title} image`}
                  />
                </div>
              )}

              <div className="flex flex-col justify-center items-center">
                <Text disableAnimation color="black" size="small">
                  {author?.name ?? "Cosimo Zecchi"}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
const SidebarPreview = ({
  slug,
  title,
  subtitle,
  category,
  image,
  author,
  createdAt,
}) =>
  category && (
    <Link to={`/${category?.slug}/${slug}/`}>
      <div className="flex  rounded-lg">
        <div className="flex flex-1 flex-row">
          <div className="w-1/3">
            <div className="bg-red-100 h-20 w-20 md:w-32 md:h-32 2xl:h-48 2xl:w-48 relative shadow-invButton rounded-md border-3 border-black">
              <GatsbyImage
                objectFit="cover"
                image={image}
                className="h-full w-full rounded"
                alt={`${title} image`}
              />
            </div>
          </div>
          <div className="w-2/3 flex flex-col justify-start items-start">
            <div className="px-2 my-2 hidden">
              <div className="mr-2">
                {/* <Text size="small" color="white">
                  | {post.category.name}
                </Text> */}
                <Label {...category} />
              </div>
              <div className="mr-4">
                <Text disableAnimation size="smaller" color="black" opaque>
                  <Label name={renderDate(createdAt)} />
                </Text>
              </div>
            </div>
            <div className="px-2">
              <Subtitle bold>{title}</Subtitle>
            </div>
            <div className="px-2 my-2">
              <div className="flex flex-row z-30">
                {author?.picture && (
                  <div className="mr-2">
                    <GatsbyImage
                      objectFit="cover"
                      image={getImage(author?.picture)}
                      className="h-6 w-6 md:h-8 md:w-8 rounded-full"
                      alt={`${title} image`}
                    />
                  </div>
                )}

                <div className="flex flex-col justify-center items-center">
                  <Text disableAnimation color="black" size="small">
                    {author?.name ?? "Cosimo Zecchi"}
                  </Text>
                </div>
              </div>
            </div>
            {/* <div className="">
                <Text size="small" color="white">
                  {readingTime} min di lettura
                </Text>
              </div> */}
          </div>
        </div>
      </div>
    </Link>
  );

const PostPreview = ({ version, ...props }) => {
  if (version === "hero") return <HeroPreview {...props} />;
  if (version === "small") return <SmallPreview {...props} />;
  if (version === "sidebar") return <SidebarPreview {...props} />;
  return <Preview {...props} />;
};

export default PostPreview;
