import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Title } from "components";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0) rotate(5deg) scale(10);
    

}

  90% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0deg) scale(1);

}
    
  to {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
  
`;

const FallingText = ({ children, color = "white", size = "small" }) => (
  <Reveal
    keyframes={customAnimation}
    duration={400}
    childClassName="ease-in-expo"
    className="transition-all ease-in-expo"
  >
    <Title margin={false} weight="black" color={color} size={size}>
      {children}
    </Title>
  </Reveal>
);

export default FallingText;
