import React from "react";
import PropTypes from "prop-types";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0) rotate(5deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
`;

const Title = ({
  center,
  right,
  children,
  weight,
  color,
  red,
  size,
  margin,
  headingLevel,
  disableAnimation,
}) => {
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : "";
  const TitleTag = validHeadingLevels.includes(safeHeading)
    ? safeHeading
    : "span";

  const title = (
    <TitleTag
      className={` tracking-tighter leading-none
        ${
          size === "base" ? "text-5xl lg:text-6xl xl:text-7xl 2xl:text-7xl" : ""
        }
        ${
          size === "small"
            ? "text-2xl md:text-4xl xl:text-4xl 2xl:text-4xl"
            : ""
        }
        ${size === "large" ? "text-4xl md:text-5xl 2xl:text-5xl" : ""}
        ${size === "xl" ? "text-4xl md:text-7xl 2xl:text-7xl" : ""}
        ${size === "hero" ? "text-5xl md:text-8xl 2xl:text-8xl" : ""}
        ${color === "primary" ? "text-primary" : ""}
        ${color === "secondary" ? "text-black" : ""}
        ${color === "black" ? "text-black" : ""}
        ${color === "white" ? "text-white" : ""}
        ${color === "gray" ? "text-gray-400" : ""}
        ${weight === "regular" ? "font-semibold" : ""}
        ${weight === "bold" ? "font-bold" : ""}
        ${weight === "black" ? "font-black" : ""}
        ${center ? "text-center" : ""}
        ${right ? "text-right" : ""}
        ${margin ? "pb-4" : ""}`}
    >
      {children}
    </TitleTag>
  );

  if (disableAnimation) return title;
  return (
    <Reveal triggerOnce keyframes={customAnimation}>
      {title}
    </Reveal>
  );
};

Title.defaultProps = {
  size: "base",
  margin: true,
  color: "black",
  weight: "regular",
  center: false,
  right: false,
  children: "Title",
  disableAnimation: false,
};

Title.propTypes = {
  size: PropTypes.oneOf(["small", "base", "large", "xl", "hero"]),
  color: PropTypes.oneOf(["primary", "secondary", "black", "gray", "white"]),
  weight: PropTypes.oneOf(["regular", "bold", "black"]),
  center: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.any.isRequired,
  margin: PropTypes.bool,
  small: PropTypes.bool,
  disableAnimation: PropTypes.bool,
};

export default Title;
