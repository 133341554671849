const renderDate = (date) => {
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  if (!date) return "...";
  try {
    const pDate = new Date(date);
    return pDate.toLocaleDateString("it-IT", options);
  } catch {
    return "Qui qualcuno ha sbagliato a inserire la data :?";
  }
};

export default renderDate;
